import Router from "./router";
import { ThemeProvider } from "@emotion/react";
import { ThemeProvider as MuiThemProvider } from '@mui/material/styles';
import ErrorBoundary from 'react-error-boundaries'
import { themeCSS, theme } from './theme';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from '../src/store'


const App = () => {
  const onError = (error, errorInfo, props) => {
    console.log('error', error, errorInfo, props)
  }

  return (
    <Provider store={store}>
      <ErrorBoundary onError={onError}>
        <BrowserRouter>
          <MuiThemProvider theme={theme}>
            <ThemeProvider theme={themeCSS}>
              <Router />
            </ThemeProvider>
          </MuiThemProvider>
        </BrowserRouter>
       </ErrorBoundary>
    </Provider>
  );
}

export default App;
