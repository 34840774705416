import React, { useEffect, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Section1BackImg from '../../assets/newImg/home/homeBg.png'
import downloadIcon from '../../assets/icon/downloadIcon.svg'
import Section1EarthImg from '../../assets/newImg/home/home_section1_earth.png'
import Section1EarthImgPhone from '../../assets/newImg/home/home_section1_phone_background.png'
import dipperImg from '../../assets/newImg/home/dipper.png'
import meteorImg from '../../assets/newImg/home/meteor.png'
import UFOImg from '../../assets/newImg/home/UFO.png'
import Section2BackImg from '../../assets/newImg/home/home_section2_background.png'
import Section2BackImgMobile from '../../assets/newImg/home/home_section2_backgroundMobile.png'
import Section3BackImg from '../../assets/newImg/home/home_section3_background.png'
import Section4BackImg from '../../assets/newImg/home/home_section4_background.png'
import Section5Img from '../../assets/newImg/home/home_section5_phone.png'
import Section6Img from '../../assets/newImg/home/Section6Img.png'
import Section9Img from '../../assets/newImg/home/section9bg.png'
import Section9mobileBg from '@/assets/newImg/home/section9mobileBg.png'
import QrCodeBg from '@/assets/newImg/home/bg_qrcode.png'
import AppleImg from '../../assets/icon/apple.png'
import AndroidImg from '../../assets/icon/android.png'
import GoogleImg from '../../assets/icon/GoogleIcon.png'
import { MyContext } from '../../layout/context';
// @ts-ignore
import { devices } from '../../device'
// @ts-ignore
import { navigate } from 'hookrouter'
//@ts-ignore
import AnimatinObserve from '../../utils/Interaction-observer';
// import Carousel from 'react-material-ui-carousel'
import CarouselNew from './components/swipperNew'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { linkSelector } from '../../store/links/linkSlice'
import { useAppSelector } from '../../hooks/useStore'
import { CommentList1, CommentList2 } from './comments'
import getQRcodeImg from '../../utils/qrcode'


interface CustomDotProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  active: boolean;
}
 
interface IImg {
  name: string,
  src: string,
  version: string;
}

const DOWNMAP: IImg[] = [
  { name: 'App Store', src: AppleImg, version: '1.0.9' },
  { name: 'Google Play', src: GoogleImg, version: '1.0.9' },
  // { name: 'Google Play', src: GoogleImg },
  { name: 'Android', src: AndroidImg, version: '1.0.9' }
]
  
const Home = (): JSX.Element => {
  // const { showToolBar } = useContext(MyContext) as any;
  const { scrollToContact } = useContext(MyContext) as any;
  const versionInfo = useAppSelector(linkSelector)
  const { t } = useTranslation();

  const [isMobileDevice] = useState(() => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  })
  const [viewportWidth] = useState(window.innerWidth);
  const [viewportHeight] = useState(window.innerHeight);
  const [animateHidden, setAnimateHidden] = useState('animation_hiden')
  const handleContextMenu = (e:any) => {
    e.preventDefault(); // 阻止默认右键菜单事件
  };
  useEffect(() => {
    if(!Object.values(versionInfo).length) return
    AnimatinObserve('.animation_hiden')
  }, [versionInfo])

  useEffect(() => {
    if(scrollToContact > 0 ){
      setAnimateHidden('')
      scrollFun()
    }
  }, [scrollToContact])

  const scrollFun = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'auto'
    });
  }
  const handleAccess = () => {
    // showToolBar()
    const userAgent = navigator.userAgent;
    // 检测是否为 iOS 设备
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      window.open(versionInfo['app_store'])
      return;
    }
    // 检测是否为 Android 设备
    if (/Android/.test(userAgent)) {
      window.open(versionInfo['android_apk'])
      return;
    }
  }

  const handleDownload = (item: string) => {
    switch (item) {
      case 'App Store':
        window.open(versionInfo['app_store'])
        break;
      case 'Google Play':
        window.open(versionInfo['google_play'])
        break;
      case 'Android':
        window.open(versionInfo['android_apk'])
        break;
      default:
    }
  }

  const Section1 = () => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    useEffect(() => {
      const generateQRCode = async () => {
        try {
          getQRcodeImg(versionInfo['app_store'], 'qrCode_ios');
          getQRcodeImg('https://www.zapry.net/dl/Zapry.apk', 'qrCode_android');
        } catch (error) {
          console.error('Failed to generate QR code', error);
        }
      };
      if(!isMobileDevice) {
        generateQRCode();
      }
    },[])
    return (
      <Section1Box backImg={Section1BackImg} 
      innerWidth={viewportWidth}
      innerHeight={viewportHeight} 
      phoneBackImg={''}>
        <div className='textBox'>
          <h3>{t("home.section1.mainTitle1")}</h3>
          <h3>{t("home.section1.mainTitle2")}</h3>
          <h3>{t("home.section1.mainTitle3")}</h3>
          <h4>{t("home.section1.subTitle")}</h4>
          <Button
            className='accessButton'
            variant="contained"
            onClick={handleAccess}
            >
              <div className='flexBox'>
                <img style={{width: '24px', height: '24px'}} src={downloadIcon} alt='_downloadIcon'></img>
                <span>{t("home.section1.buttonText")}</span>
              </div>
          </Button>
          <div className='downloadBox'>
             {
              DOWNMAP.map((item: any, index: number) =>
                <div
                  className='downloadItem'
                  key={index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleDownload(item.name)}>
                  <img src={item.src} alt="_logo" />
                  <div className='flexBox_col'>
                    {/* <p className='verison'>Version: {versionInfo['version']}</p> */}
                    <p className='verison'>{t('home.downloadDesc')}</p>
                    <p className='name'>{item.name}</p>
                  </div>
                  { (index === 0 || index === 2) && 
                    <div className={`qrCodeBox ${(hoveredIndex === 0 || hoveredIndex === 2) && (hoveredIndex === index) && 'active'}`}>
                      <img className={`${(hoveredIndex === 0 || hoveredIndex === 2) && (hoveredIndex === index) && 'active'} qrcodeBg`} src={QrCodeBg} alt="_qrcode" />
                      <img className={`${(hoveredIndex === 0 || hoveredIndex === 2) && (hoveredIndex === index) && 'active'} qrcode`} 
                        id={index === 0 ? 'qrCode_ios' : 'qrCode_android'}></img>
                    </div>
                  }
                </ div>
              )
            } 
          </div>
          {isMobileDevice && <>
            <img className='dipperImg' src={dipperImg} alt="_dipperImg" />
            <img className='meteorImg' src={meteorImg} alt="_meteorImg" />
            <img className='UFOImg' src={UFOImg} alt="_UFOImg" />
          </>}
        </div>
        
        {isMobileDevice ? 
          <img src={Section1EarthImgPhone} alt="_earth" />
          : <img src={Section1EarthImg} alt="_earth" />
        }
      </Section1Box>
    )
  }

  const Section2 = () => {
    return (
      <Section2Box backImg={Section2BackImg}>
        <div className={`topic ${animateHidden}`} >
          <h3>{t("home.section2.title")}</h3>
          <img src={isMobileDevice ? Section2BackImgMobile : Section2BackImg} alt="_backImg" />
        </div>
        <div className={`descText ${animateHidden}`}>{t("home.section2.desc")}</div>
      </Section2Box>
    )
  }

  const Section3 = () => {
    return (
      <Section3Box>
        <img src={Section3BackImg} alt="_walletImg" className={`${animateHidden}`}/>
        <div className={`textBox ${animateHidden}`}>
          <h3>{t("home.section3.title")}</h3>
          <p>{t("home.section3.content")}</p>
        </div>
      </Section3Box>
    )
  }

   const Section4 = () => {
    return (
      <Section4Box>
        <div className='textBox'>
          <h3>{t("home.section4.title")}</h3>
          <p>{t("home.section4.content")}</p>
        </div>
        <img src={Section4BackImg} alt="_phoneImg" className={`${animateHidden}`}/>
      </Section4Box>
    )
   }
  
  const Section5 = () => {
    return (
      <Section5Box>
        <img src={Section5Img} alt="_phoneImg" className={`${animateHidden}`}/>
        <div className={`textBox ${animateHidden}`}>
          <h3>{t("home.section5.title")}</h3>
          <p>{t("home.section5.content")}</p>
        </div>
      </Section5Box>
    )
  }

  const Section6 = () => {
    return (
      <Section6Box >
        <div className='textBox'>
          <h3>{t("home.section6.title")}</h3>
          <p>{t("home.section6.content")}</p>
        </div>
        <img src={Section6Img} alt="_phoneImg" className={`${animateHidden}`}/>
      </Section6Box>
    )
  }

   const Section7 = () => {
    const settings1 = {
      dots: false,
      infinite: true,
      speed: 25000,
      slidesToShow: isMobileDevice ? 1 : 4, // 设置一次显示的 item 数量
      slidesToScroll: 5,
      autoplay: true,
      autoplaySpeed: 0,
      pauseOnHover: false,
      swipeToSlide: false,
      pauseOnFocus: false,
      cssEase: "linear",
    };
    const settings2 = {
      dots: false,
      infinite: true,
      speed: 20000,
      slidesToShow: isMobileDevice ? 1 : 4, // 设置一次显示的 item 数量
      slidesToScroll: 5,
      autoplay: true,
      autoplaySpeed: 0,
      swipeToSlide: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      cssEase: "linear"
    };

    return (
      <Section7Box innerWidth={viewportWidth} innerHeight={viewportHeight}>
        <div>
          <h3 className={`title ${animateHidden}`}>{t("home.section7.title")}</h3>
        </div>
        {isMobileDevice && <CarouselNew innerWidth={viewportWidth}>
            {CommentList1.map((item: any, index: number) => (
            <div className='cardBoxNew' key={index}>
              <div className="header">
                <img src={require(`@/assets/newImg/commentsAvatar/${item.name}.jpg`)} alt="_avatar" />
                <div className='header_text'>
                  <h1>{item.name}</h1>
                  <h2>{item.position}</h2>
                </div>
              </div>
              <h3>{item.comment}</h3>
            </div>
          ))}
        </CarouselNew>}
        {isMobileDevice && <CarouselNew innerWidth={viewportWidth}>
            {CommentList2.map((item: any, index: number) => (
            <div className='cardBoxNew' style={{transform: 'translateX(-60%)'}} key={index}>
              <div className="header">
                <img src={require(`@/assets/newImg/commentsAvatar/${item.name}.jpg`)} alt="_avatar" />
                <div className='header_text'>
                  <h1>{item.name}</h1>
                  <h2>{item.position}</h2>
                </div>
              </div>
              <h3>{item.comment}</h3>
            </div>
          ))}
        </CarouselNew>}
        {/* 使用reac-slick库实现的自动匀速轮播会因手机浏览器导航条导致视口高度改变，autoplay重置 */}
        {!isMobileDevice && <Slider className={`swiperBox ${animateHidden}`} {...settings1}>
          {CommentList1.map((item: any, index: number) => (
            <div className='cardBox' key={index}>
              <div className="header">
                <img src={require(`@/assets/newImg/commentsAvatar/${item.name}.jpg`)} alt="_avatar" />
                <div className='header_text'>
                  <h1>{item.name}</h1>
                  <h2>{item.position}</h2>
                </div>
              </div>
              <h3>{item.comment}</h3>
            </div>
          ))}
        </Slider>}
        {!isMobileDevice && <Slider className={`swiperBox ${animateHidden}`} {...settings2}>
          {CommentList2.map((item: any, index: number) => (
            <div className='cardBox' key={index}>
              <div className="header">
                <img src={require(`@/assets/newImg/commentsAvatar/${item.name}.jpg`)} alt="_avatar" />
                <div className='header_text'>
                  <h1>{item.name}</h1>
                  <h2>{item.position}</h2>
                </div>
              </div>
              <h3>{item.comment}</h3>
            </div>
          ))}
        </Slider>}
      </Section7Box>
    )
   }
  
   const CustomDot: React.FC<CustomDotProps> = ({ onClick, active }) => {
    const dotBox = {
      width: '180%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
    const dotStyle: React.CSSProperties = {
      width: active ? '240%' : '80%',
      height: '10px',
      backgroundColor: active ? '#5570FA' : '#C5CEE8', // 如果当前项被激活，则应用红色样式
      borderRadius: '5px',
      padding: active ? '0 14px' : '0',
      margin: '8px',
      cursor: 'pointer',
      transition: 'width 0.3s ease, background-color 0.3s ease', // 添加过渡效果
    };
  
    return (
      <div style={dotBox}>
        <div onClick={onClick} style={dotStyle}>
          &nbsp;
        </div>
      </div>
    );
  };
  
  const Section8 = () => {
    const [currentSlide, setCurrentSlide] = useState<number>(1); // 用于跟踪当前活动幻灯片的索引
    const settings = {
      dots: true,
      accessibility: false,
      infinite: true,
      speed: 500,
      slidesToShow: isMobileDevice ? 1 : 3, // 设置一次显示的 item 数量
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: true,
      initialSlide: 1,
      centerMode: true, // 启用居中模式
      centerPadding: '80px',
      cssEase: "linear",
      afterChange: (current: number) => {
        setCurrentSlide(current); // 更新当前活动幻灯片的索引
      },
      appendDots: (dots:any) => (
        <div
          style={{
            transform: 'translateY(4%)',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <ul style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100px'
          }}> {dots} </ul>
        </div>
      ),
      customPaging: (index: number) => (
        <CustomDot onClick={() => {}} active={index === currentSlide} /> // 根据当前活动幻灯片的索引来激活对应的 dot
      ),
    };
    return (
      <Section8Box innerWidth={viewportWidth} innerHeight={viewportHeight}>
        <div>
          <h3 className='title'>{t("home.section8.title")}</h3>
        </div>
        <Slider className={`swiperBox ${animateHidden}`} {...settings}>
          <div className='swiperItems'>
            <div className='inner'>
              <img src={require('@/assets/newImg/home/section8Img1.png')} alt="" />
              <div className='innerTextBar'>
                <h1>{t("home.section8.title1st")}</h1>
                <h2>{t("home.section8.desc1st")}</h2>
              </div>
            </div>
          </div>
          <div className='swiperItems'>
            <div className='inner'>
              <img src={require('@/assets/newImg/home/section8Img2.png')} alt="" />
              <div className='innerTextBar'>
                <h1>{t("home.section8.title2nd")}</h1>
                <h2>{t("home.section8.desc2nd")}</h2>
              </div>
            </div>
          </div>
          <div className='swiperItems'>
            <div className='inner'>
              <img src={require('@/assets/newImg/home/section8Img3.png')} alt="" />
              <div className='innerTextBar'>
                <h1>{t("home.section8.title3rd")}</h1>
                <h2>{t("home.section8.desc3rd")}</h2>
              </div>
            </div>
          </div>
        </Slider>
      </Section8Box>
    )
  }

  const Section9 = () => {
    return (
      <Section9Box mobileBg={Section9mobileBg} innerHeight={viewportHeight} innerWidth={viewportWidth} >
        <div className={`textBox ${animateHidden}`}>
          <h1>{t("home.section9.title")}</h1>
          <p>{t("home.section9.desc")}</p>
          <div className='btnBox' onClick={() => { window.open(`mailto:${'feedback@cyberflow.social'}`,'_blank')}}>
            <h2>{t("home.section9.btnText")}</h2>
            <img src={require('@/assets/newImg/home/blueArrow.png')} alt="" />
          </div>
        </div>
        <img src={Section9Img} alt="_phoneImg" className={`${animateHidden}`}/>
      </Section9Box>
    )
  }

  return (
    <Mainbox onContextMenu={handleContextMenu}>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
    </Mainbox>
  )
}

export default Home


const Mainbox = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #ffffff;
`

const Section1Box = styled.section<any>`
  position: relative;
  height: ${props => (props.innerHeight * 0.72)}px;
  min-height: 900px;
  background: url(${props => props.backImg}) 100%;
  background-size: cover;
  padding: 0 17%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  

  @media only screen and ${devices.mobile} {
    padding: 31% 6% 0 6%;
    background: url(${props => props.phoneBackImg}) 100%;
    background-size: cover;
    background-color: #5570FA;
    height: ${props => (props.innerHeight * 0.92)}px;
    display: block;
    min-height: auto;
  }
  // @media only screen and (min-width: 1800px) { 
  //   padding: 10% 17% 0 17%;
  // }
  > .textBox {
      position: relative;
      top: 18%;
      width: 735px;
      z-index: 9;
      height: 37%;
      @media only screen and ${devices.mobile} {
        width: 100%;
        height: 50%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
      }
      .dipperImg {
        position: absolute;
        right: 3%;
        top: -3.6rem;
        width: 115px;
        height: 100px;
        transform: rotate(-15deg);
      }
      .meteorImg {
        position: absolute;
        left: 18.8%;
        top: -2.6rem;
        width: 60px;
        height: 28px;
      }
      .UFOImg {
        position: absolute;
        left: -5%;
        top: -2%;
        width: 70px;
        height: 60px;
      }
      > span {
        color: ${props => props.theme.color};
        font-weight: 700;
        font-size: 75px;
        line-height: 90px;
        letter-spacing: 0.02em;
        @media only screen and ${devices.mobile} {
          font-size: 47px;
          line-height: 56px;
        }
      }
      > h3 {
        font-style: normal;
        font-weight: 943;
        font-size: 4.3rem;
        line-height: 74px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        @media only screen and ${devices.mobile} {
          font-size: 38px;
          line-height: 40px;
        }
      }
      > h4 {
        font-style: normal;
        font-weight: 943;
        font-size: 4.3rem;
        line-height: 74px;
        letter-spacing: -0.01em;
        background: #11FFED;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media only screen and ${devices.mobile} {
          font-size: 38px;
          line-height: 56px;
        }
      }
      > p {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #FFFFFF;
        opacity: 0.8;
         @media only screen and ${devices.mobile} {
          margin: 20px 0;
          font-size: 16px;
          line-height: 16px;
        }
      }
      > .downloadBox {
        display: flex;
        align-items: center;
        margin-top: 10%;
        > .downloadItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 180px;
          height: 56px;
          background: #000;
          border-radius: 27px;
          margin-right: 20px;
          cursor: pointer;
          position: relative;
          .qrCodeBox {
            position: absolute;
            bottom: -90px;
            left: 16px;
            width: 148px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              position: absolute;
              opacity: 0;
              transition: opacity 0.5s ease-in-out;
            }
            .active {
              opacity: 1;
            }
            .qrcodeBg {
              width: 148px;
              height: 156px;
              z-index: 0;
            }
            .qrcode {
              margin-top: 10px;
              width: 128px;
              height: 128px;
            }
          }
          > .flexBox_col {
            display: flex;
            flex-direction: column;
            margin-left: 4px;
            > .name {
              font-weight: 600;
              font-size: 17px;
              color: #FFFFFF;
            }
            > .verison {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #FFFFFF;
            }
          } 
          > img {
            width: 44px;
            height: 44px;
            margin-left: 6px;
          }
          > .tip {
            font-weight: 500;
            font-size: 8px;
            line-height: 11px;
            color: #E9EBF9;
            opacity: 0.8;
            margin: 8px 0;
          }
          
          &:hover {
            background: rgba(0, 0, 0, .63);
          }    
        }
        @media only screen and ${devices.mobile} {
          display: none;
        }
      }
      > .accessButton {
        display: none;
        margin-top: 61px;
        background: #000 !important;
        text-transform: none;
        font-weight: bold;
        width: 70% !important;
        height: 60px;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        border-radius: 54px;
        transform: translateY(31px);
        color: #FFF;
        
        > .flexBox {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          > img {
            margin-right: 7.5px;
          }
          > span {
            font-weight: 790;
            font-size: 17px;
          }
        }
        &:hover {
          box-shadow: rgba(0, 0, 0, 0) 0 8px 15px 2px;
          // transform: translateY(60px);
        }
        @media only screen and ${devices.mobile} {
          display: block;
          width: 100%;
          margin-top: 0px;
          }
        }
      }
    > img {
      position: absolute;
      bottom: 0;
      right: ${props => (props.innerWidth * 0.15 / 2)}px;
      width: 50%;
      @media only screen and ${devices.mobile} {
        max-width: 99%;
        max-height: 100%;
        bottom: 0;
        z-index: 1;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
      }
`

const Section2Box = styled.section<any>`
  position: relative;
  width: 100%;
  height: 450px;
  background-color: #EDF2FF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and ${devices.mobile} {
    height: auto;
    padding: 20% 0;
  }
  > .topic {
    position: relative;
    width: 840px;
    height: 124px;
    @media only screen and ${devices.mobile} {
      width: 90%;
      height: 78px;
    }
    > h3 {
      font-style: normal;
      font-weight: 790;
      font-size: 60px;
      text-align: center;
      color: #323232;
      text-align: center;
      margin-bottom: 50px;
      @media only screen and ${devices.mobile} {
        font-size: 34px;
        line-height: 47px;
        margin-bottom: 30px;
      }
    }
    > img {
      position: absolute;
      top:-52px;
      width: 840px;
      height: 124px;
      @media only screen and ${devices.mobile} {
        width: 100%;
        height: auto;
        left: -2%;
        top: 0;
      }
    }
  }
  > .descText {
    max-width: 60%;
    font-size: 34px;
    font-weight: 457;
    text-align: center;
    @media only screen and ${devices.mobile} {
      font-size: 16px;
    }
  }
`

const Section3Box = styled.section<any>`
  position: relative;
  width: 100%;
  background: #F6F7FF;
  padding: 8% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media only screen and ${devices.mobile} {
    padding: 15%;
    flex-direction: column;
    align-items: center;
    // height: 450px;
  }
  > img {
    height: 507px;
    @media only screen and ${devices.mobile} {
      width: 340px;
      height: 240px;
    }
  }
  > .textBox {
    margin-left: 80px;
    height: 50%;
    @media only screen and ${devices.mobile} {
      text-align: center;
      width: 100%;
      margin-left: 0px;
    }
    > h3 {
      font-style: normal;
      font-weight: 790;
      font-size: 60px;
      text-align: left;
      letter-spacing: 0.02em;
      color: #323232;
      margin-bottom: 43px;
      @media only screen and ${devices.mobile} {
        text-align: center;
        width: 100%;
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    > p {
      font-style: normal;
      font-weight: 457;
      font-size: 26px;
      line-height: 37px;
      letter-spacing: 0.02em;
      color: #323232;
      @media only screen and ${devices.mobile} {
          font-size: 16px;
          line-height: 20px;
          font-weight: 457;
          text-align: center;
          word-wrap: break-word;
          -webkit-word-wrap: break-word;
          letter-spacing: 0;
      }
    }
  }
`

const Section4Box = styled.section<any>`
  position: relative;
  width: 100%;
  background: #EDF2FF;
  padding: 8% 10% 8% 17%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and ${devices.mobile} {
    padding: 15%;
    // height: 479px;
    flex-direction: column-reverse;
    align-items: center;
  }
  > .textBox {
    color: #323232;
    font-style: normal;
    width: 40%;
    @media only screen and ${devices.mobile} {
      width: 100%;
    }
    > h3 {
      font-weight: 700;
      font-size: 60px;
      margin-bottom: 40px;
      @media only screen and ${devices.mobile} {
        top: 0%;
        text-align: center;
        width: 100%;
        font-size: 38px;
        line-height: 47px;
        margin-bottom: 20px;
      }
    }
    > p {
        font-weight: 400;
        font-size: 28px;
        line-height: 37px;
        letter-spacing: 0.02em;
        @media only screen and ${devices.mobile} {
          font-size: 16px;
          font-weight: 457;
          line-height: 20px;
          text-align: center;
          word-wrap: break-word;
          -webkit-word-wrap: break-word;
          letter-spacing: 0;
      }
    }
  }
  > img {
    max-height: 507px;
    align-items: center;
    @media only screen and ${devices.mobile} {
      width: 340px;
      height: 240px;
      }
    }
  }
`

const Section5Box = styled.section<any>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #F6F7FF;
  padding: 8%;
  overflow: hidden;
  @media only screen and ${devices.mobile} {
    padding: 15% 0;
    flex-direction: column;
    align-items: center;
  }
    > .textBox {
    width: 40%;
    color: #323232;
    display: flex;
    flex-direction: column;
    font-style: normal;
    letter-spacing: 0.02em;
    margin-left: 80px;
    @media only screen and ${devices.mobile} {
      width: 80%;
      margin-left: 0;
      align-items: center;
    }
    > h3 {
      font-weight: 700;
      font-size: 60px;
      margin-bottom: 30px;
      @media only screen and ${devices.mobile} {
        top: 0%;
        text-align: center;
        width: 100%;
        font-size: 38px;
        line-height: 47px;
        margin-bottom: 20px;
      }
    }
    > p {
      font-weight: 457;
      font-size: 26px;
      line-height: 37px;
      @media only screen and ${devices.mobile} {
        width: 350px;
        font-size: 16px;
        font-weight: 457;
        line-height: 20px;
        text-align: center;
        word-wrap: break-word;
        -webkit-word-wrap: break-word;
        letter-spacing: 0;
      }
    }
  }
  > img {
      align-items: center;
      max-height: 507px;
      @media only screen and ${devices.mobile} {
        height: 240px;
      }
    }
  }
`
const Section6Box = styled.section<any>`
  position: relative;
  width: 100%;
  background: #EDF2FF;
  padding: 8% 10% 8% 17%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and ${devices.mobile} {
    padding: 15%;
    flex-direction: column-reverse;
    align-items: center;
  }
  > .textBox {
    color: #323232;
    font-style: normal;
    max-width: 40%;
    margin-right: 40px;
    @media only screen and ${devices.mobile} {
      max-width: 100%;
      text-align: center;
      margin-right: 0;
    }
    > h3 {
      font-weight: 700;
      font-size: 60px;
      margin-bottom: 40px;
      @media only screen and ${devices.mobile} {
        top: 0%;
        text-align: center;
        width: 100%;
        font-size: 38px;
        line-height: 47px;
        margin-bottom: 20px;
      }
    }
    > p {
      font-weight: 457;
      font-size: 26px;
      line-height: 37px;
        @media only screen and ${devices.mobile} {
          width: 100%;
          font-size: 16px;
          font-weight: 457;
          line-height: 20px;
          text-align: center;
          word-wrap: break-word;
          -webkit-word-wrap: break-word;
          letter-spacing: 0;
      }
    }
  }
  > img {
    max-height: 507px;
    @media only screen and ${devices.mobile} {
      width: 340px;
      height: 240px;
      }
    }
  }
`

// s
const Section7Box = styled.section<any>`
  position: relative;
  width: 100%;
  background: #F6F7FF;
  padding: 3% 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media only screen and ${devices.mobile} {
    height: 760px;
    padding-top: 0;
    padding-bottom: 12%;
  }
  .title {
    margin-top: 3%;
    color: #323232;
    font-size: 60px;
    font-weight: 790;
    margin-bottom: 8%;
    @media only screen and ${devices.mobile} {
      font-size: 30px;
      margin-bottom: 30px;
      margin-top: 50px;
    }
  }
  .cardBoxNew {
    width: ${props => props.innerWidth * 0.78}px;
    height: 266px;
    margin-right: 20px;
    border-radius: 28px;
    background: #fff;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    
    .header {
      display: flex;
      flex-direction: row;
      align-item: center;
      img {
        border-radius: 50%;
        width: 58px;
        height: 58px;
        object-fit: cover;
      }
      .header_text {
        height: 58px;
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > h1 {
          color: #323232;
          font-size: 20px;
          font-weight: 790;
        }
        > h2 {
          color: #767E8B;
          font-size: 16px;
          line-height: 20px;
          font-weight: 457;
        }
      }
    }
    > h3 {
      color: #5C5C5C;
      font-size: 16px;
      font-weight: 457;
      @media only screen and ${devices.mobile} {
        margin-top: 16px;
      }
    }
  }
  > .swiperBox {
    width: 100%;
    
     .cardBox {
      width: 92% !important;
      height: 100%;
      background: #fff;
      border-radius: 28px;
      padding: 20px;
      margin-bottom: 6%;
      @media only screen and ${devices.mobile} {
        height: 90%;
        width: 92% !important;
      }
      .header {
        display: flex;
        flex-direction: row;
        align-item: center;
        img {
          border-radius: 50%;
          width: 58px;
          height: 58px;
          object-fit: cover;
        }
        .header_text {
          height: 58px;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          > h1 {
            color: #323232;
            font-size: 20px;
            font-weight: 790;
          }
          > h2 {
            color: #767E8B;
            font-size: 16px;
            line-height: 20px;
            font-weight: 457;
          }
        }
      }
      > h3 {
        color: #5C5C5C;
        font-size: 16px;
        font-weight: 457;
        line-height: 24px;
        width: 100%;
        margin-top: 16px;
        @media only screen and ${devices.mobile} {
          margin-top: 16px;
        }
      }
    }
  }
`
const Section8Box = styled.section<any>`
  position: relative;
  width: 100%;
  // height: ${props => props.innerHeight * 0.7}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5% 0;
  background-color: #EDF2FF;
  overflow: hidden;
  @media only screen and ${devices.mobile} {
    text-align: center;
    padding: 8% 0 20%;
  }
  .title {
    color: #323232;
    font-size: 60px;
    font-weight: 790;
    margin-bottom: 60px;
    text-align: center;
    @media only screen and ${devices.mobile} {
      font-size: 38px;
      margin-bottom: 30px;
    }
  }
  .swiperBox {
    width: 90%;
    @media only screen and ${devices.mobile} {
      width: 100%;
    }
    .swiperItems {
      display: flex !important;
      justify-content: center !important;
      width: 100%;
      @media only screen and ${devices.mobile} {
        height: ${props => (props.innerWidth * 0.5) / 0.8}px;
      }
      .inner {
        border-radius: 28px;
        width: 90%;
        display: flex;
        flex-direction: column;
        padding-bottom: 49px;
        @media only screen and ${devices.mobile} {
          width: ${props => props.innerWidth * 0.50}px;
          height: ${props => (props.innerWidth * 0.5) / 0.8}px;
          border-radius: 14px;
          padding-bottom: 24px;
        }
        img {
          width: 100%;
        }
        .innerTextBar {
          background: #fff;
          width: 100%;
          padding: 8% 2%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 0 0 28px 28px;
          @media only screen and ${devices.mobile} {
            border-radius: 0 0 14px 14px;
          }
          h1 {
            color: #323232;
            font-size: 32px;
            font-weight: 790;
            text-align: center;
            @media only screen and ${devices.mobile} {
              font-size: 16px;
            }
          }
          h2 {
            color: #5C5C5C;
            font-size: 24px;
            font-weight: 457;
            text-align: center;
            margin-top: 3%;
            @media only screen and ${devices.mobile} {
              font-size: 12px;
            }
          }
          h3 {
            font-size: 24px;
            @media only screen and ${devices.mobile} {
              font-size: 12px;
            }
          }
        }
        h1 {
          color: #323232;
          font-size: 32px;
          font-weight: 790;
          text-align: center;
          @media only screen and ${devices.mobile} {
            font-size: 16px;
          }
        }
        h2 {
          color: #5C5C5C;
          font-size: 24px;
          font-weight: 457;
          text-align: center;
          @media only screen and ${devices.mobile} {
            font-size: 12px;
          }
        }
      }
    }
  }
`

const Section9Box = styled.div<any>`
  position: relative;
  width: 100%;
  height: ${props => props.innerHeight * 0.38}px;
  background: #5570FA;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  padding: 3% 0;
  @media only screen and (min-width: 1000px) {
    height: ${props => props.innerHeight * 0.48}px;
  }
  @media only screen and ${devices.mobile} {
    height: ${props => props.innerWidth / 1.25}px;
    width: ${props => props.innerWidth}px;
    background: url(${props => props.mobileBg}) 100%;
    background-size: cover;
  }
  > img {
    position: absolute;
    bottom: 0;
    z-index:0;
    width: 100%;
    @media only screen and ${devices.mobile} {
      width: 0;
    }
  }
  .textBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    position: relative;
    z-index: 1;
    h1 {
      font-size: 60px;
      font-weight: 790;
      color: #fff;
      @media only screen and ${devices.mobile} {
        font-size: 38px;
      }
    }
    p {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      width: 600px;
      text-align: center;
      @media only screen and ${devices.mobile} {
        margin: 15px 0 25px;
        font-size: 16px;
        line-height: 22px;
        width: 80%;
      }
    }
    .btnBox {
      width: 228px;
      height: 70px;
      border-radius: 35px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and ${devices.mobile} {
        width: 50%;
        height: 54px;
      }
      h2 {
        color: #5570FA;
        font-size: 24px;
        font-weight: 900;
        @media only screen and ${devices.mobile} {
          font-size: 18px;
        }
      }
      img {
        width: 28px;
        height: 28px;
      }
      &:hover {
        box-shadow: 0px 4px 26px 1px rgba(0, 0, 0, 0.15);
        transform: translateY(-2px);
        background: rgba(255, 255, 255, .8)
      }
    }
  }
`

