// @ts-ignore
import qrcode from 'qrcode'

// 根据网址生成链接
const getQRcodeImg = (url, domId) => {
  const opt = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 0.7,
    margin: 1,
    color: {}
  }

  qrcode.toDataURL(url, opt, function (error, url) {
    if (error) console.error(error)
    const qrcodeImg = document.getElementById(domId)
    qrcodeImg.src = url
    return url
  })
}

export default getQRcodeImg


