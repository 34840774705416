import React from "react";
import MenuBar from './MenuBar'
import Footer from './Footer'
import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { MyContext  } from "./context";


interface IProps {
  children: React.ReactElement
  hasFooter?: boolean
}

const defaultProps = {
   hasFooter: true
}

const Layout = (props: IProps): JSX.Element => {
  props = { ...defaultProps, ...props }
  const layoutRef: any = useRef<any>('')
  const [scrollToContact, setScrollToContact] = useState(0)
  
  const showToolBar = () => {
    layoutRef.current.show()
  }

  return (
    <LayoutBox>
      <MyContext.Provider value={{ showToolBar, scrollToContact, setScrollToContact }}>
        <MenuBar ref={layoutRef} />
        <MainBox>
          { props.children  }
        </MainBox>
      </MyContext.Provider>
      {
        props.hasFooter ? <Footer /> : null
      }
    </LayoutBox>
  )
}

export default Layout

const LayoutBox = styled.div`
  height: 100%;
  width: 100%;
`

const MainBox = styled.div`
`