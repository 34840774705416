const CommentList1: any = [
  {
    name: 'Fred',
    position: 'Developer',
    comment: "As a developer, I appreciate the open platform/APIs this app offers. It's great to have the freedom to build new apps and integrate with the community. The chat features are smooth, and it's cool that they cater to a wide age range. Definitely recommend it!”"
  },
  {
    name: 'Zech',
    position: 'Community Manager',
    comment: "“As a Community Manager, I’ve had the privilege of being a part of this amazing web 3 community app. The platform’s innovative features and commitment to user engagement make my role even more exciting.”"
  },
  {
    name: 'Cara',
    position: 'Student',
    comment: "“Being a student, I enjoy the diversity of this app. It's not limited to any age group, which is great for my social circle. The chat features are slick, making it easy to connect with classmates and friends. It's my go-to social app!”"
  },
  {
    name: 'Cherry',
    position: 'General Users',
    comment: "“This app is like the best of both worlds – socializing and gaming. I've met awesome people here, and the game selection keeps me hooked. It's a great option for teens and young adults who want to connect and have a good time.”"
  },
  {
    name: 'Jack',
    position: 'General Users',
    comment: "“This app is like the best of both worlds – socializing and gaming. I've met awesome people here, and the game selection keeps me hooked. It's a great option for teens and young adults who want to connect and have a good time.”"
  }
]

const CommentList2: any = [
  {
    name: 'Jerry',
    position: 'Customer Support Specialist',
    comment: "“As a customer support specialist, I love the user-friendly interface and smooth chat features of this app. It makes my job easier, and I can efficiently assist our users. They also cater to various age groups, ensuring everyone's needs are met.”"
  },
  {
    name: 'Papi',
    position: 'Developer',
    comment: "“As a developer, I appreciate the open platform/APIs this app offers. It's great to have the freedom to build new apps and integrate with the community. The chat features are smooth, and it's cool that they cater to a wide age range. Definitely recommend it!”"
  },
  {
    name: 'Cindy',
    position: 'General Users',
    comment: "“I was skeptical at first, but this app won me over. The e-wallet feature is handy, and I've discovered some amazing games. The open platform encourages creativity, and the chat functions make it easy to stay in touch. A must-try for the 13-35 age group!”"
  },
  {
    name: 'Camilia',
    position: 'Influencer',
    comment: "“I'm always on the lookout for platforms that help me connect with my audience. This app's open APIs are a game-changer for content creation. It allows me to engage with a wide audience range, boosting my online presence.”"
  },
  {
    name: 'Kelly',
    position: 'Business Owner',
    comment: "“Running a business, I'm always looking for new ways to connect with my customers. This app provides a perfect platform to engage with our community.”"
  }
]

export { CommentList1, CommentList2 }