const sizes = {
  mobileS: '375px',
  mobileM: '425px',
  mobile: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

// export const devices = {
//   mobileS: `(max-width: 375px)`,
//   mobileM: `(max-width: 425px)`,
//   mobile: `(max-width: '768px')`,
//   laptop: `(max-width: 1024px)`,
//   laptopL: `(max-width: 1440px))`,
//   desktop: `(max-width: 2560px)`,
// };

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobile: `(max-width: ${sizes.mobile})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};