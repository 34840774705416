import React, { forwardRef, ReactNode, useState, useImperativeHandle } from 'react';
import Snackbar from '@mui/material/Snackbar';

interface IProps {
  showTime: number
}

const Toast = forwardRef((props: IProps, ref: any) => {
  const {  showTime } = props
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<any>('')

  const handleStatus = (show: boolean, message?: ReactNode | string | any): void => {
    message && setMessage(message)
    setOpen(show)
  }

  useImperativeHandle(
    ref,
    () => ({
        showToast(show: boolean, message: ReactNode | string | any) {
          handleStatus(show, message)
        }
      }),
  )
  
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical:'top', horizontal: 'center' }}
      onClose={() => handleStatus(false)}
      autoHideDuration={showTime}
      message={message}
    />
  )
})

export default Toast

