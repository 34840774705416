import React, { useState } from 'react';
import styled from '@emotion/styled';

type CarouselProps = {
  children: React.ReactNode;
  innerWidth: number;
};

const Carousel: React.FC<CarouselProps> = ({ children, innerWidth }) => {
  const [currentIndex] = useState(0);
  const slides = React.Children.toArray(children);
  const detectDeviceOS = () => {
    const userAgent = navigator.userAgent;
  
    // 检测是否为 iOS 设备
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return 'iOS';
    }
  
    // 检测是否为 Android 设备
    if (/Android/.test(userAgent)) {
      return 'Android';
    }
  
    // 如果不是 iOS 或 Android 设备
    return 'Other';
  };
  const deviceOS = detectDeviceOS();

  return (
    <CarouselBox deviceOS={deviceOS} innerWidth={innerWidth}>
      <div className="slides-container" style={{ transform: `translateX(-${(currentIndex * 300)})` }}>
        {[...slides, slides[0], slides[1]].map((slide, index) => (
          <div key={index} className="slide">
            {slide}
          </div>
        ))}
      </div>
    </CarouselBox>
  );
};
const CarouselBox = styled.div<any>`
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    margin-bottom: 30px;
    .slides-container {
      display: flex;
      /* transition: transform 5s linear;  */
      position: absolute;
      animation: ${props => props.deviceOS === 'iOS' ? 'scrollAnimationForLeft' : 'scrollAnimation'} 50s linear infinite;
    }
    @keyframes scrollAnimation {
      0% {
        transform: translate3d(0,0,0);
        /* left: 0; */
      }
      100% {
        transform: translate3d(-${props => props.innerWidth * 0.78 * 5 + 100}px,0,0);
        /* left: -1600px; */
      }
    }
    @keyframes scrollAnimationForLeft {
      0% {
        left: 0;
      }
      100% {
        left: -${props => props.innerWidth * 0.78 * 5 + 100}px;
      }
    }
`

export default Carousel;
