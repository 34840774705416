import React from "react";
import logoText from '../assets/logo/logo_text.png'
import colorfulLogo from '../assets/logo/logo_text.png'
import logoForMenu from '../assets/logo/logoForMenu.png'
import styled from "@emotion/styled";
// @ts-ignore
import { navigate } from 'hookrouter'

interface IProps {
  className?: string,
  type?: string
}
const Logo = (props: IProps): JSX.Element => {
  const navigateTohome = (): void => {
     navigate('/')
  }
  const pickLogo = (type?:string) => {
    switch(type) {
      case 'logoText':
        return logoText
      case 'colorfulLogo':
        return colorfulLogo
      case 'logoForMenu':
        return logoForMenu
      default:
        return logoForMenu
    }
  }

  return (
    <LogoBox src={pickLogo(props.type)} className={props.className} onClick={navigateTohome} />
  )
}

export default Logo

const LogoBox = styled.img`
  width: 112px;
  height: 32px;
  cursor: pointer;
`