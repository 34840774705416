import type { RootState } from '../index'
import links from '../../link'
import {
  createSlice,
  PayloadAction,
  createSelector
} from '@reduxjs/toolkit';


// const androidAPKUrl = (process.env as any).REACT_APP_CDN + '_' + (process.env as any).REACT_APP_APKVERSION + '.apk'
const version = (process.env as any).REACT_APP_APKVERSION

export interface IInfo {
  android_apk: string;
  version: string;
  test_flight: string;
  app_store : string;
  google_play: string;
}

export interface ILinkState {
  versionInfo: IInfo
};

const initialState: ILinkState = {
  versionInfo: {
    android_apk: links.androidApk,
    version,
    test_flight: links.testFlight,
    app_store: links.appStore,
    google_play: 'https://play.google.com/store/apps/details?id=social.cyberflow.zapry&pli=1'
  }
};

export const linkSlice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    updateLink: (state, action: PayloadAction<IInfo>) => {
      state.versionInfo = action.payload
    },
  },
});

export const {
  updateLink,
} = linkSlice.actions;

export const selectLink = (state: RootState) => (state.link as any).versionInfo;

export const linkSelector = createSelector(selectLink, state => state);

export default linkSlice.reducer;