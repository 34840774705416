import styled from '@emotion/styled'
import Logo from '../components/Logo'
import { t } from "i18next";
// @ts-ignore
import { devices } from '../device'

interface IProps {
  opacityBackGround?: boolean
}

const defaultProps = {
  opacityBackGround: false
}

const Footer = (props: IProps): JSX.Element => {
  props = { ...defaultProps, ...props } as Required<IProps>
  
  return (
    <FooterBox opacityBackGround={props.opacityBackGround}>
      <Logo className='ss' type={'colorfulLogo'} />
      <p>{t("footer.rights")}</p>
    </FooterBox>
  )
}

export default Footer

const FooterBox = styled.footer<IProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 130px;
  padding: 0 17%;
  background: ${props => props.opacityBackGround ? 'none' : '#090A11'}; 
  @media only screen and ${devices.mobile} {
    flex-direction: column;
    padding: 4% 6%;
    justify-content: center;
  } 
  > p {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.50);
  letter-spacing: 0.14px;
  left: 30px;
  @media only screen and ${devices.mobile} {
    font-size: 12px;
    margin-bottom: 10px;
    letter-spacing: 0.24px;
    left: 0;
    top: 4px;
  } 
    &::after{
      content: "";
      opacity: 0.5;
      width: 1px;
      height: 30px;
      border: 1px solid #5E616E;
      position: absolute;
      left: -20px;
      top: -25%;
      @media only screen and ${devices.mobile} {
        display: none;
      } 
    }
  }

  > .iconBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 283px;
    @media only screen and ${devices.mobile} {
      justify-content: unset;
    } 
    > img {
      cursor: pointer;
       @media only screen and ${devices.mobile} {
        Width: 30px;
        height: 30px;
        margin-right: 5px;
      } 
    }
  }
`