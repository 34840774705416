import React, { useState, useEffect, useRef, forwardRef, useContext } from "react"
import styled from "@emotion/styled"
import { useTranslation } from 'react-i18next'
import Logo from '../components/Logo'
import Popover from '@mui/material/Popover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { MyContext } from './context';
// @ts-ignore
import { navigate, usePath } from 'hookrouter'
// @ts-ignore
import { devices } from '../device'
import Drawer from '@mui/material/Drawer';
import Toast from '../components/Toast'
import { useAppSelector} from '../hooks/useStore'
import { linkSelector } from "../store/links/linkSlice"

interface IProps {}

const ROUTEMAP: {[key: string]: string } = {
  'HOME': '/'
}

// const LAGNG_OPTIONS = ['English', '繁体中文']
const LAGNG_OPTIONS = ['English']


const MenuBar = forwardRef((props: IProps, ref: any): JSX.Element => {
  const path = usePath();
  const toastRef = useRef() as any
  const { setScrollToContact } = useContext(MyContext) as any;
  const [activeMenu, setMenu] = useState('Home')
  const [openDrawer, setDrawerOpen] = useState<boolean>(false)
  const [menuStick] = useState<boolean>(false)
  const { t, i18n } = useTranslation();
  const menuLists = t('home.memuBar', { returnObjects: true }) as string[];
  const versionInfo = useAppSelector(linkSelector)
  // const downloadLists = t('home.downloadOptions', { returnObjects: true }) as string[];
  const [viewportWidth] = useState(window.innerWidth);
  const detectDeviceOS = () => {
    const userAgent = navigator.userAgent;
  
    // 检测是否为 iOS 设备
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return 'iOS';
    }
  
    // 检测是否为 Android 设备
    if (/Android/.test(userAgent)) {
      return 'Android';
    }
  
    // 如果不是 iOS 或 Android 设备
    return 'Other';
  };
  const deviceOS = detectDeviceOS();
  
  if (ref) {
      ref.current = {
        show() {
          setDrawerOpen(true)
      }
    }
  };

  useEffect(() => {
    const matchedMenu = Object.keys(ROUTEMAP).reduce((prev: string, cur: string) => {
      prev = ROUTEMAP[cur] === path ? cur : prev
      return prev
    }, '')
    // 吸顶导航条，暂时注释，新版本暂无要求
    // window.addEventListener('scroll', handleScroll)
    if (!matchedMenu) {
      setMenu('')
      return
    }
    if (matchedMenu && activeMenu !== matchedMenu) {
      setMenu(matchedMenu)
    }
    // return () => {
    //   window.removeEventListener('scroll', handleScroll)
    // }
  }, [path, activeMenu])

  const handleDownload = (item: string) => {
    switch (item) {
      case 'App Store':
        window.open(versionInfo['app_store'])
        break;
      case 'Testflight':
        navigate('/download/testflight')
        break;
      case 'MIMO Apk':
        window.open(versionInfo['android_apk'])
        break;
      default:
        toastRef.current.showToast(true, t("toast.unavailable"))
    }
  }

  const handleMenuClick = (item:string) => {
    setDrawerOpen(false)
    switch (item) {
      case 'Download':
        if(deviceOS === 'iOS') {
          handleDownload('App Store')
        } else {
          handleDownload('MIMO Apk')
        }
        break;
      case 'Contact':
        hrefFun()
        break;
      default:
        break;
    }
  }

  const changLanuage = (lang: 'en' | 'zh') => {
    i18n.changeLanguage(lang)
  }

  // const handleScroll = (e: any) => {
  //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  //   scrollTop <= 60 && setMenuStick(false)
  //   scrollTop > 60 && setMenuStick(true)
  // }
  // @ts-ignore
  const LanguageSelect = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
    setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
      <>
      <LanguageBox onClick={handleClick}>
        <p>{t("home.lang")}</p>
        <ExpandMoreIcon
          sx={{ color: '#FFFFFF' }} />
      </LanguageBox>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -20,
          horizontal:'center'
        }}>
        <LanguageOption>
          {
            LAGNG_OPTIONS.map((item: string, index: number) => 
              <p key={index}
                onClick={()=> changLanuage(item === "English" ? 'en' : 'zh')
              }>{item}</p>
            )
          }
        </LanguageOption>
      </Popover>
      </>
    )
  }

  const ToolBar = () => {
    return (
      <ToolBarBox>
        <img src={require('@/assets/newImg/home/menuIcon.png')} alt="_menu" onClick={() => setDrawerOpen(true)}/>
      </ToolBarBox>
     )
  }

  const hrefFun = () => {
    window.open(`mailto:${'feedback@cyberflow.social'}`,'_blank')
  }

  const Contact = () => {
    return (
      <ContactBox onClick={() => {
        setScrollToContact((lastVal:any) => {
          return lastVal += 1
        })
      }}>
        <ContactText>{t("home.contact")}</ContactText>
      </ContactBox>
    )
  }


  return (
    <MenuBarBox stick={ menuStick}>
      <Logo type={'logoText'} />
      <RightSection >
        {/* <LanguageSelect /> */}
        <Contact />
      </RightSection>
      <ToolBar />
      
      <Drawer
        open={openDrawer}
        anchor={"right"}
        onClose={() => setDrawerOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}>
        <ToolContent innerWidth={viewportWidth}>
          <div className="topRow">
            <Logo className="logo" type={'menu'}/>
            <CloseButtonBox onClick={()=> setDrawerOpen(false)} />
          </div>

        {
          menuLists.map((item: string, index: number) => 
            <MenuRow key={index}
              innerWidth={viewportWidth}
              // onClick={()=> handleDrawerClick('menu', item) }>
              onClick={()=> handleMenuClick(item) }>
              { item }
            </MenuRow>
            )
          }
        </ToolContent>
      </Drawer>

      <Toast ref={toastRef} showTime={3000}/>
    </MenuBarBox>
  )
})

export default MenuBar

const MenuBarBox = styled.div<{stick: boolean}>`
  position: ${props => props.stick? 'fixed': 'absolute'};
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 17%;
  z-index: 33;
  background: ${props => props.stick ? '#000000' : 'none'};
  justify-content: space-between;
  
   @media only screen and ${devices.mobile} {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6%;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 40%;
  padding-left: 8%;

  @media only screen and ${devices.mobile} {
    display: none;
  }
`

const ToolBarBox = styled.div`
  display: none;
    @media only screen and ${devices.mobile} {
      display: unset;
      img {
        width: 22px;
        height: 19px;
      }
    }
`

const ToolContent = styled.div<any>`
  background: #FFFFFF;
  width: ${props => props.innerWidth * 0.85}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .topRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
      
  }
`
const ContactBox = styled.div`
  background: #FFFFFF;
  padding: 9px 11px;
  border-radius: 26px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 26px 1px rgba(0, 0, 0, 0.15);
  }
`
const ContactText = styled.div`
  color: black;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    color: #5570FA;
  }
`

const CloseButtonBox = styled(CloseIcon)<any>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #323232;
`

const MenuRow = styled.div<any>`
  Height: 40px;
  width: 90%;
  color: #323232;
  font-weight: 400;
  font-size: 16px;
  padding-left: 30px;
  line-height: 40px;
  border-radius: 15px;
  margin-bottom: 10px;
  &:hover {
    background: #5570FA;
    color: #FFFFFF;
  }
`

const LanguageBox = styled.div`
 display: flex;
 margin-left: 20px;
 align-items: center;
 cursor: pointer;
 > p {
  color: #FFFFFF;
  flex-shrink: 0;
 }
`

const LanguageOption = styled.div`
  width: 90px;
  padding: 6px;
  text-align: center;
  background: #476B78;
  border-radius: 10px; 
   >  :last-child{
    margin-bottom: 0 !important;
   }
  > p {
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 10px;
  }
`