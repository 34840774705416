import { createTheme } from '@mui/material/styles';

// 主题颜色
const themeCSS: Record<string, string>  = {
  color: '#43FFBB',
  background: '#000000',
  background2: '#1D1D1D'
}

const theme = createTheme({
  palette: {
    primary: {
      main: themeCSS.color,
    }
  },
});


export {
  themeCSS,
  theme
}