/* eslint-disable no-unused-expressions */
const AnimatinObserve = (className) => {
  const options = {
    root: document.querySelector('#scrollArea'), // default window // 父窗口对象
    rootMargin: '0px', // default 0 // 拓展监听对象范围
    threshold: 0.25 // (0 ~ 1.0) default 0 // 1 === 当完全进入画面 // 回调阈值 eg: 0, [0, 0.25, 0.75, 1]...
  }

  const callback = (entries, observer) => {
    entries.forEach(entry => { // 递归监听参数对象
      if (entry.isIntersecting) {
        const ele = entry.target
        ele.classList.add('animation_show')
        observer.unobserve(entry.target)
      }
    })
  }

  const observer = new IntersectionObserver(callback, options)

  Array.from(document.querySelectorAll(className)).forEach(elem => observer.observe(elem))
}

export default AnimatinObserve



