import { useRoutes } from 'hookrouter'
import Layout from '../layout'
import Home from '../view/home'

const withLayout = (page, hasFooter = true) => {
  return <Layout hasFooter={hasFooter}>{page}</Layout>
}

const routes = {
  '/': () => withLayout(<Home />),
}

function RouteComponents()   {
    return useRoutes(routes)
}

export default RouteComponents